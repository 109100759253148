import requests from "./AjaxAxios"

const Ajax = {
    Questionnaire: {
        Get: async (id) =>                                                  await requests.get(`/respondent/questionnaire/${id}`),
        Update: async (id, answers: string) =>                              await requests.put(`/respondent/questionnaire/${id}`, answers),
        Submit: async (id) =>                                               await requests.put(`/respondent/questionnaire/submit/${id}`, null),
    },
    Upload: {
        Get: async (uid: string) =>                                         await requests.get(`/respondent/upload/${uid}`),
        Delete: async (uid: string) =>                                      await requests.delete(`/respondent/upload/${uid}`),
        Create: async (uid: string, displayName: string, eType: string, formData: FormData) => 
                                                                            await requests.post(`/respondent/upload/${uid}/${displayName}/${eType}`, formData),
        CreateAndExtract: async (uid: string, displayName: string, eType: string, formData: FormData) =>
                                                                            await requests.post(`/respondent/upload-extract/${uid}/${displayName}/${eType}`, formData),
    },
    Public: {
        Login: async (questionnaireUid, accessCode) =>                      await requests.post("/public/login", {uid: questionnaireUid, accessCode: accessCode}),
        GetAdvisorAccount: async (advisorUid) =>                            await requests.get(`/public/advisor/${advisorUid}`)
    }
}

export default Ajax
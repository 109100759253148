import React from "react"
import * as Yup from "yup"
import Ajax from "../../Ajax"
import { context } from "../.."
import { useFormik } from "formik"
import FieldRadio from "./FieldRadio"
import { Icons } from "../../misc/Icons"
import { IFieldProps, IUpload } from "../../interfaces"
import { Button, CircularProgress } from "@mui/material"


interface FormValues {
    file: File | null;
    evidence_type: string | null;
}


interface FileWithMetadata extends File {
    type: string;
    size: number;
}


const getTypesValidationMessage = (allowedFormats) => {
    var al = [...allowedFormats]
    // Add commers
    if (al.length > 2) {
        al = al.map((type, i) => {
            return i < allowedFormats.length - 2 ? `${type},` : type
        })
    }
    // Add 'or'
    if (al.length > 1) {
        var indexOneFromEnd = al.length - 1
        al.splice(indexOneFromEnd, 0, "or")
    }
    return `Your file must be a ${al.join(" ")} file`
}


export default function FieldFile ({ formik, field }: IFieldProps) {

    const upload: IUpload = context.uploads.find((u) => u.uid === formik.values[field.name])

    // Format validation
    const fileFormats = context.getListSet("file_formats")
    const allowedFormats = field.allowed_formats ? field.allowed_formats : Object.keys(fileFormats)
    const allowedMimeTypes = allowedFormats.map(type => fileFormats[type])

    // File size validation
    const maxBytes = field.max ? field.max : 1000000
    const maxKb = (maxBytes / 1000).toFixed(1)
    const maxMb = (maxBytes / 1000000).toFixed(1)
    const fileSizeMessage = maxBytes >= 1000000 ? `File size must be less than ${maxMb}MB` : `File size must be less than ${maxKb}KB`

    const initialEvidenceType = field.evidence_types?.length === 1 ? field.evidence_types[0] : null

    const fileFormik = useFormik<FormValues>({

        initialValues: {
            file: null,
            evidence_type: upload?.evidenceType ? upload?.evidenceType : initialEvidenceType
        },

        validationSchema: Yup.object({
            file: Yup.mixed<FileWithMetadata>()
                .required('A file is required')
                .test('fileFormat', getTypesValidationMessage(allowedFormats), value => {
                    return value && allowedMimeTypes.includes(value.type);
                })
                .test('fileSize', fileSizeMessage, value => {
                    return value && value.size <= field.max;
                }),
        }),

        onSubmit: async (values, actions) => {
            await context.uploadFile(field, values.file, values.evidence_type).then((upload: IUpload) => {
                
                var newValues = {...formik.values, [field.name]: upload.uid}
                
                if (upload.extractedInfo) {
                    var extractedInfo = JSON.parse(upload.extractedInfo)
                    
                    if (Object.keys(extractedInfo).includes("subject_details")) {
                        newValues = {...newValues, ...extractedInfo.subject_details}
                    }
                }

                formik.setValues(newValues)
            
            }).catch((response) => {
                fileFormik.setFieldError("file", response.data?.detail ? response.data.detail : "Upload failed")
            })
        }
    })

    const evidenceTypesOptions = {}
    field.evidence_types.forEach((et) => {
        evidenceTypesOptions[et] = et
    })
    const prototypeSelectField = {type: "select", name: "evidence_type", options: evidenceTypesOptions}
    const disabled = formik.values[field.name] === "null"

    return (
        <div className="form-field field-file">

            <label>{field.label}</label>

            {field.evidence_types?.length > 1 ? (
                <>
                    <FieldRadio formik={fileFormik} field={prototypeSelectField} disabled={!!upload} />
                    
                    {fileFormik.values.evidence_type 
                        ? <label>Upload a copy of your {fileFormik.values.evidence_type}</label>
                        : <label>Upload your evidence</label>}
                </>
            ) : null}

            {upload 
                ? <FileUploaded field={field} formik={formik} upload={upload} />
                : <FileForm field={field} fileFormik={fileFormik} disabled={disabled} />}

            {!upload && !fileFormik.values.file ? (
                <div className="form-field field-checkbox">
                    <label>
                        <input
                            checked={disabled}
                            type="checkbox"
                            onChange={(e) => formik.setFieldValue(field.name, disabled ? "" : "null")} />

                        <span className="field-checkbox-label-content">Continue without uploading</span>
                    </label>
                </div>
            ) : null}
            
            {formik.submitCount && formik.errors[field.name] ? (
                <span className="warning">{formik.errors[field.name]}</span>
            ) : null}
        </div>
    )
}


function FileForm ({field, fileFormik, disabled = false}) {

    const pasteFromClipboard = (e) => {
        
        var file = null
        const items = [...(e.clipboardData || e.originalEvent.clipboardData).items];
        
        items.forEach(item => {
            if (items.length && items[0].kind === "file") {
                file = items[0].getAsFile()
            }
        })

        if (file) {
            fileFormik.setFieldValue("file", file)
        }
    }

    return (
        <>
            <div className={!fileFormik.values.evidence_type || disabled ? "disabled field-file-input" : "field-file-input"}>
                <label htmlFor="file">
                    <Icons.UploadFile />
                </label>

                <input
                    hidden
                    type="file"
                    id="file"
                    name="file"
                    multiple={false}
                    onChange={(e) => {
                        if (e.target.files?.length) {
                            fileFormik.setFieldValue("file", e.target.files[0]).then(() => {
                                fileFormik.handleSubmit()
                            })
                        } else {
                            fileFormik.setFieldValue("file", null)
                        }
                    }}
                />

                {fileFormik.values.file ? (
                    <div style={{width: "100%", display: "grid", gap: "10px"}}>
                        <div style={{display: "flex", gap: "10px", justifyContent: "space-between", color: "grey"}}>
                            <span style={{overflowWrap: "anywhere"}}>{fileFormik.values.file.name}</span>
                            
                            {fileFormik.values.file.size >= 1000000 
                                ? <span>{(fileFormik.values.file.size / 1000000).toFixed(1)}mb</span>
                                : <span>{(fileFormik.values.file.size / 1000).toFixed(1)}kb</span>}
                        </div>
                        
                        <div style={{display: "flex", gap: "10px"}}>
                            <Button
                                disabled={fileFormik.isSubmitting}
                                className="btn btn-md"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                    fileFormik.resetForm()
                                    // fileFormik.setFieldValue("file", null)
                                    // The physical input element must be reset like this because it can't have 2 way binding.
                                    var input = document.getElementById("file") as HTMLInputElement
                                    if (input?.value) {
                                        input.value = null
                                    }
                                }}>
                                Cancel
                            </Button>

                            <Button
                                className="btn btn-md"
                                variant="contained"
                                color="success"
                                disabled={fileFormik.isSubmitting}
                                onClick={() => fileFormik.handleSubmit()}>
                                {fileFormik.isSubmitting ? (
                                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                                        <CircularProgress style={{width: "12px", height: "12px"}} />
                                        <span>Uploading</span>
                                    </div>
                                ) : "Upload"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <input
                            className="file-paste-input"
                            onPaste={(e) => pasteFromClipboard(e)}
                            onChange={() => {}}
                            placeholder="or Ctrl+V to paste" />
                    </div>
                )}
            </div>

            {fileFormik.submitCount && fileFormik.errors.file ? (
                <span className="warning">{fileFormik.errors.file?.toString()}</span>
            ) : null}

            {field.helpText ? (
                <span className="help-text">{field.helpText}</span>
            ) : null}
        </>
    )
}


function FileUploaded ({field, formik, upload}) {

    const [deleting, setDeleting] = React.useState(false)
    const [showDetails, setShowDetails] = React.useState(false)
    const [fullUpload, setFullUpload] = React.useState(upload.data ? upload : false)

    React.useEffect(() => {
        if (!fullUpload) {
            Ajax.Upload.Get(upload.uid).then((response) => {
                setFullUpload(response.data)
            })
        }
    }, [])

    const deleteUpload = async () => {
        setDeleting(true)
        
        await Ajax.Upload.Delete(formik.values[field.name]).catch((response) => {
            context.displayError("Couln't delete file")
        }).then(() => {
            context.submitPageAnswers({[field.name]: ""}, false)
            context.setUploads(context.uploads.filter(u => u.uid !== upload.uid))
        })

        setDeleting(true)
    }

    return (
        <div className="field-file-input">
            <label onClick={(e) => {
                if (e.ctrlKey) {
                    setShowDetails(!showDetails)
                }
            }}>
                <Icons.Photo />
            </label>

            {showDetails ? (
                <div style={{backgroundColor: "whitesmoke", whiteSpace: "pre", fontFamily: "monospace", overflow: "scroll"}}>
                    {JSON.stringify({...fullUpload, extractedInfo: JSON.parse(fullUpload.extractedInfo)}, null, "\t")}
                </div>
            ) : (
                <div style={{color: "grey", display: "grid"}}>
                    
                    {upload?.displayName 
                        ? <span style={{overflowWrap: "anywhere"}}>{upload.displayName}</span> 
                        : <span>File has been uploaded</span>}

                    {fullUpload.data && ["JPG", "JPEG", "PNG"].includes(fullUpload.fileFormat)
                        ? <img src={context.formatDataString(fullUpload)} alt={upload.uid} style={{maxHeight: "300px", maxWidth: "300px", margin: "10px 0 5px"}} />
                        : <div style={{margin: "5px"}}></div>}

                    {fullUpload.data && ["PDF"].includes(fullUpload.fileFormat)
                        ? <iframe title={fullUpload.uid} src={context.formatDataString(fullUpload)} style={{maxHeight: "300px", maxWidth: "300px", margin: "10px 0 5px"}} ></iframe>
                        : <div style={{margin: "5px"}}></div>}

                    <Button
                        color="error"
                        variant="contained"
                        className="btn btn-md"
                        disabled={deleting}
                        onClick={() => deleteUpload()}>
                        Delete attachment
                    </Button>

                </div>
            )}
        </div>
    )
}
import Ajax from './Ajax'
import { action, makeAutoObservable, reaction, runInAction } from 'mobx'
import listSets from './misc/listSets.json'
import { IAdvisorAccount, IField, IPage, ITemplate, IUpload } from './interfaces'

export default class AppContext {

    embedded = window.self !== window.top
    advisorAccount: IAdvisorAccount = null
    questionnaireUid: string = null
    answers: any = {}
    uploads: IUpload[] = []
    template: ITemplate
    respondentEmail: string

    locked: boolean = false
    submitting: boolean = false
    listSets: any = listSets
    pageIndex: number = null // Current page
    errors: string[] = []
    showPreviousAnswers = false

    token: string = window.localStorage.getItem("aora_questionnaire_jwt")

    constructor() {
        makeAutoObservable(this)

        reaction(() => this.token, token => {
            if (token) {
                window.localStorage.setItem("aora_questionnaire_jwt", token)
            } else {
                window.localStorage.removeItem("aora_questionnaire_jwt")
                // Reset
                this.questionnaireUid = null
                this.answers = {}
                this.uploads = []
                this.template = null
                this.respondentEmail = null
                this.locked = false
                this.pageIndex = null
                this.showPreviousAnswers = false
            }
        })

        reaction(() => this.pageIndex, pageIndex => {
            if (this.embedded) {
                window.parent.postMessage({scrollTop: true}, "*")
            }
        })

        if (this.embedded) {
            
            document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
            var lastScrollHeight = document.body.scrollHeight
            
            setInterval(() => {
                if (lastScrollHeight !== document.body.scrollHeight) {
                    lastScrollHeight = document.body.scrollHeight
                    window.parent.postMessage({scrollHeight: document.body.scrollHeight}, "*")
                }
            }, 200)
        }
    }

    isHidden = (fieldOrPage, values = {}) => {

        var valuesStored = {...this.answers, ...values}
        var valuesToLookFor = {...fieldOrPage.dependsOn, ...fieldOrPage.dependsOnAny, ...fieldOrPage.hideDependsOn, ...fieldOrPage.hideDependsOnAny}

        var fieldsKeysSatisfied = Object.keys(valuesToLookFor).filter((key) => {
            
            var valueToLookFor = valuesToLookFor[key]
            var valueStored = valuesStored[key]
            
            return Array.isArray(valueToLookFor) 
                ? valueToLookFor.includes(valueStored) 
                : valueToLookFor === valueStored
        })

        if (fieldOrPage.dependsOn) {
            return Object.keys(valuesToLookFor).length !== fieldsKeysSatisfied.length
        }
        if (fieldOrPage.hideDependsOn) {
            return !(Object.keys(valuesToLookFor).length !== fieldsKeysSatisfied.length)
        }

        if (fieldOrPage.dependsOnAny) {
            return fieldsKeysSatisfied?.length === 0
        }
        if (fieldOrPage.hideDependsOnAny) {
            return !(fieldsKeysSatisfied?.length === 0)
        }

        return false
    }

    private getPageList = (answers, returnSubmittedPages): IPage[] => {

        var pageList = []

        const handlePages = (pages: IPage[]) => {
            pages.forEach(page => {

                if (!this.isHidden(page)) {
                    pageList.push(page)
                }

                // Check for conditional pages
                page.fields?.forEach(field => {
                    var value = answers[field.name]
                    if (field.conditional_pages) {
                        if (value in field.conditional_pages) {
                            handlePages(field.conditional_pages[value])
                        } else if ("else" in field.conditional_pages) {
                            handlePages(field.conditional_pages["else"])
                        }
                    }
                })
            })
        }

        var templateBody = JSON.parse(this.template.json)
        handlePages(templateBody.pages)

        if (!answers.submitted_pages) {
            return returnSubmittedPages ? [] : pageList
        }

        if (returnSubmittedPages) {
            return pageList.filter(page => answers.submitted_pages.includes(page.id))
        }

        return pageList.filter(page => !answers.submitted_pages.includes(page.id))
    }

    // Remove any answers which belong to fields which have been conditionally hidden
    cleanAnwers = (answers) => {

        var visibleFieldNames = []
        var pages = [...this.getPageList(answers, true)]

        // Get list of field keys for fields which are visible
        pages.forEach((page) => {
            if (page?.table?.list_name) {
                visibleFieldNames.push(page.table?.list_name)
            }
            page?.fields?.forEach(field => {
                if (!this.isHidden(field, answers)) {
                    visibleFieldNames.push(field.name)
                }
            })
        })

        // Delete any value which doesn't have a visible field
        Object.keys(answers).forEach(key => {
            if (!visibleFieldNames.includes(key) && key !== "submitted_pages") {
                delete answers[key]
            }
        })

        return answers
    }

    // If page is hidden, set submitted to false so that if it later becomes unhidden, it will be the next page shown
    resetSubmittedPages = (answers) => {

        var visiblePages = this.getPageList(answers, true)

        var submittedPages = answers.submitted_pages.filter((pageId: string) => {
            return !!visiblePages.find((page) => page.id === pageId)
        })

        // Remove duplicates
        answers.submitted_pages = [...new Set(submittedPages)]

        return answers
    }

    updateAnswers = (answersToAdd, pageComplete = true) => {

        var page = this.getPage()

        var newAnswers = {
            ...this.answers,
            ...answersToAdd,
            submitted_pages: [...this.answers.submitted_pages, page.id]
        }
        
        newAnswers = this.resetSubmittedPages(newAnswers)
        newAnswers = this.cleanAnwers(newAnswers)

        // Remove page id after cleanAnswers so that answers on current page aren't cleared
        if (!pageComplete) {
            newAnswers.submitted_pages = newAnswers.submitted_pages.filter(pageId => pageId !== page.id)
        }

        return newAnswers
    }

    uploadFile = async (field: IField, file: File, evidenceType: string): Promise<IUpload> => {
        
        var formData = new FormData()
        formData.append("file", file)

        var response = evidenceType === 'passport'
            ? await Ajax.Upload.CreateAndExtract(this.questionnaireUid, file.name, evidenceType, formData)
            : await Ajax.Upload.Create(this.questionnaireUid, file.name, evidenceType, formData)

        var uploadResponse = await Ajax.Upload.Get(response.data)
        var upload = uploadResponse.data

        this.setUploads([...this.uploads, upload])

        return upload
    }

    // If pageId is not set, answers will be saved but the page won't be tracked as submitted
    submitPageAnswers = action(async (answers, pageComplete = true) => {

        this.submitting = true
        this.errors = []
        this.answers = this.updateAnswers(answers, pageComplete)

        await Ajax.Questionnaire.Update(this.questionnaireUid, this.answers).then(action((response) => {
            this.pageIndex = null
            if (pageComplete) {
                window.scrollTo(0, 0)
            }
            if (this.embedded) {
                window.parent.postMessage({scrollTop: true}, "*")
            }
        })).catch(() => {
            this.displayError("Failed to save")
        })

        runInAction(() => {
            this.submitting = false
        })
    })

    submitQuestionnaire = action(async () => {
        this.submitting = true

        await Ajax.Questionnaire.Submit(this.questionnaireUid).then(action(() => {
            this.locked = true
        })).catch(() => {
            this.displayError("Failed to submit questionnaire")
        })

        this.submitting = false
    })

    loadQuestionnaire = async (id = this.questionnaireUid) => {

        await Ajax.Questionnaire.Get(id).then(action((response) => {
            this.answers = response.data.answers ? JSON.parse(response.data.answers) : {}

            // answers.submitted_pages MUST be defined
            if (!this.answers.submitted_pages || !Array.isArray(this.answers.submitted_pages)) {
                this.answers.submitted_pages = []
            }
            this.questionnaireUid = response.data.uid
            this.uploads = response.data.uploads
            this.template = response.data.template
            this.respondentEmail = response.data.respondent.respondentEmail
            this.locked = response.data.locked
            document.title = response.data.template.displayName
            this.errors = []
        })).catch(() => {
            // this.displayError("Failed to load questionnaire")
        })
    }

    formatDataString = (upload): string => {
        
        if (!upload?.data) {
            return null
        }
        
        var uploadType = upload.fileFormat.toLowerCase()
        var fileFormats = this.getListSet("file_formats")
        
        if (!Object.keys(fileFormats).includes(uploadType)) {
            return null
        }
        
        var mimeType = fileFormats[uploadType]
        return `data:${mimeType};base64,${upload.data}`
    }

    formatLabel = (message) => {
        if (message) {
            var placeholders = message.match(/{[a-zA-Z\-_0-9]*}/g, message)
            if (placeholders && Array.isArray(placeholders)) {
                for (var i = 0; i < placeholders.length; i++) {
                    var placeholder = placeholders[i]
                    var attribute_key = placeholder.replace("{", "").replace("}", "")
                    var value = this.answers[attribute_key]
                    if (value) {
                        message = message.replace(`${placeholder}`, value)
                    }
                }
            }
        }
        return message
    }

    backPage = () => {
        runInAction(() => {
            if (this.pageIndex === null) {
                var pageList = this.getSubmittedPages()
                this.pageIndex = pageList.length - 1
            } else {
                var newIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 0
                this.pageIndex = newIndex
            }
        })
    }

    getAdvisorAccount = async (advisorUid: string) => {
        await Ajax.Public.GetAdvisorAccount(advisorUid).then(action((response) => {
            this.advisorAccount = response.data as IAdvisorAccount
        }))
    }

    getListSet = (setKey) => {
        if (Object.keys(this.listSets).includes(setKey)) {

            var sortedListSet = {}
            var listSet = this.listSets[setKey]

            var items = Object.keys(listSet).map(function(key) {
                return [key, listSet[key]];
            })

            // Sort the array based on the second element
            items.sort((a, b) => a[1].localeCompare(b[1]))

            items.forEach((item) => {
                sortedListSet[item[0]] = item[1]
            })

            return sortedListSet
        }

        return {}
    }

    getPage = () => {
        if (this.pageIndex != null) {
            var submittedPages = this.getSubmittedPages()
            return submittedPages[this.pageIndex]
        }
        
        var unsubmittedPages = this.getUnsubmittedPages()
        if (unsubmittedPages.length) {
            return unsubmittedPages[0]
        }

        return null
    }

    getSubmittedPages = () => {
        return this.getPageList(this.answers, true)
    }

    getUnsubmittedPages = () => {
        return this.getPageList(this.answers, false)
    }

    togglePreviousAnswers = () => {
        this.showPreviousAnswers = !this.showPreviousAnswers
    }

    setToken = (token) => {
        this.token = token
    }

    setUploads = action((uploads: IUpload[]) => {
        this.uploads = uploads
    })

    setSubmitting = (submitting: boolean) => {
        runInAction(() => {
            this.submitting = submitting
        })
    }

    setPageIndex = (index: number) => {
        runInAction(() => {
            this.pageIndex = index
        })
    }

    displayError = (errorMessage) => {
        runInAction(() => {
            this.errors.push(errorMessage)
        })
    }

    dismissError = (errorMessage) => {
        runInAction(() => {
            this.errors = this.errors.filter((e) => e !== errorMessage)
        })
    }
}
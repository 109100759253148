import React from 'react'
import * as Yup from 'yup'
import { Button } from '@mui/material'
import { useFormik } from 'formik'
import Field from './Field.tsx'
import ErrorBoundary from '../../misc/ErrorBoundary.jsx'
import { add, compareAsc, format, isValid, sub } from 'date-fns'
import { context } from '../../index.js'
import { Icons } from '../../misc/Icons.tsx'


function dateBoundary (dateBoundary: string): Date {

    try {
        dateBoundary = dateBoundary.replace(" ", "").toLowerCase()

        if (dateBoundary === "current_date") {
            return new Date()
        }
        if (dateBoundary.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/) || dateBoundary.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
            return new Date(dateBoundary)
        } 
        if (dateBoundary.match(/(current_date)(\+|-)[0-9]{1,1000}(y|m|d)$/)) {
            var boundaryDirection = dateBoundary.includes("current_date+") // true for future, false for past
            var interval = dateBoundary.split(boundaryDirection ? "+" : "-", 2)[1]
            var intervalQuantity = parseInt(interval.slice(0, -1))
            var intervalUnit = interval.slice(-1)
            var intervalObj = {
                days: intervalUnit === "d" ? intervalQuantity : null,
                months: intervalUnit === "m" ? intervalQuantity : null,
                years: intervalUnit === "y" ? intervalQuantity : null,
            }
            var newDate = boundaryDirection ? add(new Date(), intervalObj) : sub(new Date(), intervalObj)
            return newDate
        }
    } catch (e) {
        console.log("Error while parsing date boundary", dateBoundary, e)
    }
}


export default function FormFields ({
    fields, 
    initialValues = {},
    onSubmit = (values) => {},
    onCancel = null,
    maxHeight = "none",
    submitButtonText = "Submit"
}) {

    var yupObject = {}
    
    fields.filter(field => !!field.name).forEach(field => {
        
        // Set intial value to guess
        if (!(field.name in initialValues)) {
            if (field.guess) {
                initialValues[field.name] = field.guess
            } else if (field.type === "checkbox") {
                initialValues[field.name] = "false"
            } else if (field.type === "file") {
                initialValues[field.name] = ""
                initialValues[`${field.name}_not_available`] = "false"
            } else {
                initialValues[field.name] = ""
            }
        }
        
        // Set validation
        if (field.type === "checkbox") {
            
            // yupObject[field.name] = field.required !== false 
            //     ? Yup.string().test('check', `Please confirm`, val => val === "true")
            //     : Yup.string()

        } else if (field.required !== false && field.type === "date") {
        
            yupObject[field.name] = Yup.string().test({
                name: field.name,
                // skipAbsent: true,
                test(value, ctx) {

                    var split = value?.split("/")
                    var reformattedValue = split?.reverse().join("-")
                    var date = new Date(reformattedValue)

                    if (!isValid(date)) {
                        return ctx.createError({ message: 'Enter a valid date' })
                    }

                    if (field.min) {
                        var minDate = dateBoundary(field.min)
                        if (minDate && compareAsc(date, minDate) !== 1) {
                            return ctx.createError({ message: `Enter a date after ${format(minDate, "do LLLL u")}` })
                        }
                    }

                    if (field.max) {
                        var maxDate = dateBoundary(field.max)
                        if (maxDate && compareAsc(date, maxDate) !== -1) {
                            return ctx.createError({ message: `Enter a date before ${format(maxDate, "do LLLL u")}` })
                        }
                    }

                    return true
                }
            })
        } else if (field.name && field.required !== false) {
            
            var regex = field.regex ? field.regex : "^[“”‘’ -~À-Ö\p{Sc}\p{So}\p{Mn}\p{P}\p{Z}À-ÿ\w\n]*$"
            var regexMessage = field.regexMessage ? field.regexMessage : "Only use standard European characters"
            var fieldValidation = Yup.string().matches(regex, regexMessage)

            if (field.min) {
                fieldValidation = fieldValidation.test('min', `You must enter at least ${field.min} characters`, val => val?.length >= field.min)
            }
            if (field.max) {
                fieldValidation = fieldValidation.test('max', `You are limited to ${field.max} characters`, val => val?.length <= field.max)
            }
            if (field.required !== false) {
                fieldValidation = fieldValidation.required("This field is required")
            }

            yupObject[field.name] = fieldValidation
        }
    })

    var formik = useFormik({
        
        initialValues: initialValues,
        validationSchema: Yup.object(yupObject),
        
        onSubmit: (values) => {
            return onSubmit(values)
        }
    })

    const removeHiddenValues = () => {
        var displayedFieldKeys = []
        
        fields.forEach(field => {
            if (!context.isHidden(field, formik.values)) {
                displayedFieldKeys.push(field.name)
            }
        })

        fields.forEach(field => {
            if (!displayedFieldKeys.includes(field.name)) {
                // console.log("Removing", field.name)
                delete formik.values[field.name]
                delete formik.errors[field.name]
            }
        })

        return displayedFieldKeys
    }

    React.useEffect(() => {
        removeHiddenValues()
    })

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields" style={{maxHeight: maxHeight}}>
                {fields.map((field, i) => {
                
                    if (context.isHidden(field, formik.values)) {
                        return <React.Fragment key={`${i}-${field.name}`}></React.Fragment>
                    }

                    return (
                        <ErrorBoundary key={`${i}-${field.name}`} >
                            <Field
                                field={field} 
                                formik={formik}
                                autoFocus={i === 0} />
                        </ErrorBoundary>
                    )
                })}
            </div>

            {/* <br/> */}

            <div className="form-buttons">
                {onCancel ? (
                    <Button
                        onClick={() => onCancel()}
                        className="btn btn-lg"
                        variant="contained"
                        color="info"
                        disabled={formik.isSubmitting || context.locked}>
                        <Icons.ArrowLeft /> Cancel
                    </Button>
                ) : null}
                
                <Button 
                    type="submit" 
                    className="btn btn-lg" 
                    color="success" 
                    disabled={formik.isSubmitting || context.locked}
                    variant="contained">
                    {submitButtonText}
                </Button>

                {context.locked ? <small>This questionnaire is locked</small> : null}
            </div>
        </form>
    )
}